import React from 'react';
import CreateCustomer from './create-customer';
import CustomerRow from './customer-row';
import {
  Message,
  Container,
  Segment,
  Table
} from 'semantic-ui-react'

export default ({ customers=[], creationErrors, getCustomerDetail, createCustomer, deleteCustomer }) => {
  const haveCustomers = customers.length > 0;

  return (
    <Segment basic padded='very'>
      <Container>
        <Table celled selectable={haveCustomers}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>CustomerId</Table.HeaderCell>
              <Table.HeaderCell>Customer Name</Table.HeaderCell>
              <Table.HeaderCell>Customer Domain</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Created</Table.HeaderCell>
              <Table.HeaderCell/>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {customers.map((customer, i) => (
              <CustomerRow key={i} {...{customer, deleteCustomer, getCustomerDetail}} />
            ))}
            { !haveCustomers &&
                <Table.Row>
                  <Table.Cell colSpan={6}>
                    <Message info
                      header='No customers defined'
                      content="Use the 'Add Customer' button to create a new customer" />
                  </Table.Cell>
                </Table.Row>
            }
          </Table.Body>
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan='6'>
                <CreateCustomer {...{createCustomer}} />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Container>
    </Segment>
  );
}
