import React, { useState }from 'react';
import { Link } from 'react-router-dom'
import history from '../../history'
import {
  Button,
  Confirm,
  Icon,
  Table
} from 'semantic-ui-react'

export default ({ customer, deleteCustomer, getCustomerDetail }) => {

  const [confirm, setConfirm] = useState(false);
  const {customerId, name, domain, status} = customer;

  const confirmDelete = async () => {
    await deleteCustomer(customerId);
    setConfirm(false);
  }


  return (
    <Table.Row>
      <Table.Cell>
        <Link
          to={`/customers/${customerId}`}>
          {customerId}
        </Link>
      </Table.Cell>
      <Table.Cell>{name}</Table.Cell>
      <Table.Cell>{domain}</Table.Cell>
      <Table.Cell>{status}</Table.Cell>
      <Table.Cell>
      </Table.Cell>
      <Table.Cell collapsing>
        <Button icon
          size='small'
          color='red'
          onClick={(e) => {
            setConfirm(true);
          }}>
          <Icon name='delete' />
        </Button>
        <Confirm
          open={confirm}
          onConfirm={confirmDelete}
          onCancel={() => setConfirm(false)}
          header='Delete Customer'
          content={`Are you sure you want to delete customer '${customerId}'?`} />
      </Table.Cell>
    </Table.Row>
  );
}
