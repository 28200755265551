import React__default from 'react';

/**
 * AuthenticatorContext serves static reference to the auth machine service.
 *
 * https://xstate.js.org/docs/recipes/react.html#context-provider
 */
const AuthenticatorContext = React__default.createContext(null);

export { AuthenticatorContext };
