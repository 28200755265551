import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form } from "semantic-ui-react";

const DeleteUserPool = ({ customerId, userPoolId, deleteUserPool }) => {
  // ui state
  const [open, setOpen] = useState(false);

  async function deleteUserPoolSubmit() {
    try {
      await deleteUserPool(customerId, userPoolId);

      // reset state

      setOpen(false);
    } catch (e) {
      console.error("the error", e.status);
    }
  }

  const deleteUserPoolButton = (
    <Button
      floated="right"
      negative
      disabled={userPoolId === "default"}
      size="mini"
    >
      Delete
    </Button>
  );

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={deleteUserPoolButton}
    >
      <Modal.Header>
        Are you sure you want to delete this user pool?
      </Modal.Header>
      <Modal.Content>
        <Form onSubmit={deleteUserPoolSubmit}>
          <Button.Group>
            <Button positive type="submit">
              Yes
            </Button>
            <Button.Or />
            <Button
              onClick={e => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                setOpen(false);
              }}
            >
              Cancel
            </Button>
          </Button.Group>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

DeleteUserPool.propTypes = {
  customerId: PropTypes.string.isRequired,
  userPoolId: PropTypes.string.isRequired,
  deleteUserPool: PropTypes.func.isRequired
};

export default DeleteUserPool;
