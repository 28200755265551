/* eslint react/jsx-filename-extension: 0 */
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { withAuthenticator } from "@aws-amplify/ui-react";

import CustomerList from "./customer-list";
import CustomerDetails from "./customer-details";
import CustomerUsers from "./customer-users";
import Home from "./home";

class Routes extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/customers" component={CustomerList} />
          <Route
            exact
            path="/customers/:customerId"
            component={CustomerDetails}
          />
          <Route
            path="/customers/:customerId/users"
            component={CustomerUsers}
          />
        </Switch>
      </div>
    );
  }
}

export default withAuthenticator(Routes, true);
