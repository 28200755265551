import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import {
  Checkbox,
  Button,
  Dropdown,
  Divider,
  Header,
  List,
  Radio,
  Icon,
  Form,
  Message,
  Container,
  Segment
} from "semantic-ui-react";
import CreateIdetityProvider from "./create-identity-provider";
import CreateUserPool from "./create-user-pool";
import DeleteUserPool from "./delete-user-pool";
import ManageBackups from "../customer-users/manage-backups";

class CustomerDetails extends Component {
  state = {
    modified: false,
    details: {}
  };

  constructor(params) {
    super(params);
    this.getCustomerDetail = this.getCustomerDetail.bind(this);
    this.createIdentityProvider = this.createIdentityProvider.bind(this);
    this.createUserPool = this.createUserPool.bind(this);
    this.deleteUserPool = this.deleteUserPool.bind(this);
    this.updateCustomer = this.updateCustomer.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    const { customerId } = match.params;
    this.getCustomerDetail(customerId);
  }

  async getCustomerDetail(customerId) {
    const customerDetail = await API.get(
      "audit-apis",
      `/customers/${customerId}`,
      {}
    );
    this.setState({ details: customerDetail.data });
  }

  async createUserPool(customerId, userPool) {
    await API.post("audit-apis", `/customers/${customerId}/user-pools`, {
      body: userPool
    });
    await this.getCustomerDetail(customerId);
  }

  async deleteUserPool(customerId, userPoolId) {
    await API.del(
      "audit-apis",
      `/customers/${customerId}/user-pools/${userPoolId}`,
      {}
    );
    await this.getCustomerDetail(customerId);
  }

  async createIdentityProvider(customerId, provider) {
    await API.post("audit-apis", `/customers/${customerId}/providers`, {
      body: provider
    });
    await this.getCustomerDetail(customerId);
  }

  async updateCustomer() {
    const { details } = this.state;
    const { customerId, storageMethod = "onedrive" } = details;
    const detailsAug = {
      ...details,
      storageMethod
    };
    const cleanDetails = _.pick(detailsAug, [
      "name",
      "domain",
      "storageMethod",
      "emailDomain",
      "pluginRedirect",
      "callbackUrl",
      "siteCollection",
      "identityProviders",
      "sharePointSite",
      "currUserPoolId",
      "azureClientId",
      "azureClientSecret",
      "azureTenantId",
      "azureSSO",
      "allowCognitoLogin"
    ]);
    const customerDetail = await API.put(
      "audit-apis",
      `/customers/${customerId}`,
      { body: cleanDetails }
    );
    this.setState({
      modified: false,
      details: customerDetail.data
    });
  }

  handleChange(name, value) {
    const { details } = this.state;
    return this.setState({
      modified: true,
      details: {
        ...details,
        [name]: value
      }
    });
  }

  toggleCognitoSignin() {
    const { details } = this.state;
    this.setState({
      modified: true,
      details: {
        ...details,
        allowCognitoLogin: !details.allowCognitoLogin
      }
    });
  }

  toggleAzureSSO() {
    const { details } = this.state;
    this.setState({
      modified: true,
      details: {
        ...details,
        azureSSO: !details.azureSSO
      }
    });
  }

  render() {
    const { modified, details } = this.state;
    const {
      customerId,
      name,
      domain,
      emailDomain,
      userPool,
      cognitoDomain,
      identityProviders = [],
      userPools,
      currUserPoolId,
      pluginRedirect,
      storageMethod,
      siteCollection,
      sharePointSite,
      azureClientId,
      azureClientSecret,
      azureTenantId,
      allowCognitoLogin,
      azureSSO
    } = details;
    const {
      createIdentityProvider,
      updateCustomer,
      createUserPool,
      deleteUserPool
    } = this;

    const storageOptions = [
      {
        key: "s3",
        value: "s3",
        text: "S3"
      },
      {
        key: "onedrive",
        value: "onedrive",
        text: "OneDrive"
      }
    ];

    return (
      <Container>
        {customerId && (
          <>
            <Divider horizontal>
              <Header color="blue" as="h4">
                <Icon name="info" />
                Customer Details
              </Header>
            </Divider>
            <Segment clearing>
              <Link as={Button} to={`/customers/${customerId}/users`}>
                Manage Users
              </Link>
              <Form onSubmit={updateCustomer}>
                <Form.Field>
                  <label htmlFor="customerId">Customer Id:</label>
                  <input id="customerId" disabled readOnly value={customerId} />
                </Form.Field>
                <Form.Field>
                  <label htmlFor="name">Customer Name:</label>
                  <input
                    id="name"
                    onChange={e => this.handleChange("name", e.target.value)}
                    value={name}
                  />
                </Form.Field>
                <Form.Field>
                  <label htmlFor="domain">Domain:</label>
                  <input
                    id="domain"
                    onChange={e => this.handleChange("domain", e.target.value)}
                    value={domain}
                  />
                </Form.Field>
                <Form.Field>
                  <label htmlFor="userPool">User Pool:</label>
                  <input id="userPool" disabled readOnly value={userPool} />
                </Form.Field>
                <Form.Field>
                  <label htmlFor="cognitoDomain">Cognito Domain:</label>
                  <input
                    id="cognitoDomain"
                    disabled
                    readOnly
                    value={cognitoDomain}
                  />
                </Form.Field>
                <List>
                  {userPools &&
                    userPools.map(p => {
                      return (
                        <List.Item>
                          <Form.Field>
                            <Radio
                              label={`(pool${p.num}) ${p.cognitoDomain}`}
                              name="radioGroup"
                              value={p.userPoolId}
                              checked={currUserPoolId === p.userPoolId}
                              onChange={(e, { value }) => {
                                this.handleChange("currUserPoolId", value);
                              }}
                            />
                            <ManageBackups {...{ customerId, poolInfo: p }} />
                            <DeleteUserPool
                              {...{
                                customerId,
                                userPoolId: p.userPoolId,
                                deleteUserPool
                              }}
                            />
                          </Form.Field>
                        </List.Item>
                      );
                    })}
                </List>
                <CreateUserPool {...{ customerId, createUserPool }} />
                <Form.Field>
                  <label htmlFor="emailDomain">Email Domain:</label>
                  <input
                    id="emailDomain"
                    onChange={e =>
                      this.handleChange("emailDomain", e.target.value)
                    }
                    value={emailDomain}
                  />
                </Form.Field>
                <Form.Field>
                  <label htmlFor="pluginRedirect">Plugin Redirect:</label>
                  <input
                    id="pluginRedirect"
                    onChange={e =>
                      this.handleChange("pluginRedirect", e.target.value)
                    }
                    value={pluginRedirect}
                  />
                </Form.Field>
                <Form.Field>
                  <label htmlFor="allowCognitoLogin">Allow Cognito Login</label>
                  <Checkbox
                    id="allowCognitoLogin"
                    toggle
                    onChange={() => this.toggleCognitoSignin()}
                    checked={allowCognitoLogin}
                  />
                </Form.Field>
                <Form.Field>
                  <label htmlFor="storageMethod">Storage Method:</label>
                  <Dropdown
                    id="storageMethod"
                    selection
                    onChange={(e, d) =>
                      this.handleChange("storageMethod", d.value)
                    }
                    options={storageOptions}
                    value={storageMethod || "onedrive"}
                  />
                </Form.Field>
                <Divider horizontal>
                  <Header color="blue" as="h4">
                    <Icon name="microsoft" />
                    Azure Active Directory
                  </Header>
                </Divider>
                <Form.Field>
                  <label htmlFor="clientId">Client Id</label>
                  <input
                    id="clientId"
                    onChange={e =>
                      this.handleChange("azureClientId", e.target.value)
                    }
                    value={azureClientId}
                  />
                </Form.Field>
                <Form.Field>
                  <label htmlFor="tenantId">Tenant</label>
                  <input
                    id="tenantId"
                    onChange={e =>
                      this.handleChange("azureTenantId", e.target.value)
                    }
                    value={azureTenantId}
                  />
                </Form.Field>
                <Form.Field>
                  <label htmlFor="clientSecret">Client Secret</label>
                  <input
                    id="clientSecret"
                    onChange={e =>
                      this.handleChange("azureClientSecret", e.target.value)
                    }
                    value={azureClientSecret}
                  />
                </Form.Field>
                <Form.Field>
                  <label htmlFor="siteCollection">Site Collection:</label>
                  <input
                    id="siteCollection"
                    onChange={e =>
                      this.handleChange("siteCollection", e.target.value)
                    }
                    value={siteCollection}
                  />
                </Form.Field>
                <Form.Field>
                  <label htmlFor="sharePointSite">SharePoint Site:</label>
                  <input
                    id="sharePointSite"
                    onChange={e =>
                      this.handleChange("sharePointSite", e.target.value)
                    }
                    value={sharePointSite}
                  />
                </Form.Field>
                <Form.Field>
                  <label htmlFor="enableSSO">Enable SSO</label>
                  <Checkbox
                    id="enableSSO"
                    toggle
                    onChange={() => this.toggleAzureSSO()}
                    checked={azureSSO}
                  />
                </Form.Field>
                <Button
                  floated="right"
                  disabled={!modified}
                  primary
                  type="submit"
                >
                  Update
                </Button>
              </Form>
            </Segment>
            <Divider horizontal>
              <Header color="blue" as="h4">
                <Icon name="sitemap" />
                Identity Providers
              </Header>
            </Divider>
            <Segment clearing>
              <Form>
                <List divided relaxed>
                  {identityProviders &&
                    identityProviders.map(p => {
                      return (
                        <List.Item key={p.name}>
                          <List.Content>
                            <List.Header as="a">{p.name}</List.Header>
                            <List.Description as="a">{p.type}</List.Description>
                          </List.Content>
                        </List.Item>
                      );
                    })}
                </List>
                {identityProviders && identityProviders.length === 0 && (
                  <Message
                    info
                    header="No Third Party Identity Providers Found"
                    content="Use the 'Add Provider' button to associate a new Identity Provider with this customer"
                  />
                )}
                <CreateIdetityProvider
                  {...{ customerId, createIdentityProvider }}
                />
              </Form>
            </Segment>
          </>
        )}
      </Container>
    );
  }
}

CustomerDetails.propTypes = {
  match: PropTypes.shape({
    customerId: PropTypes.string
  }).isRequired
};

export default CustomerDetails;
