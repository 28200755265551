import { Auth } from "aws-amplify";

async function getAccessToken() {
  try {
    const session = await Auth.currentSession();
    return session.idToken.jwtToken;
  } catch (e) {
    console.error("access token not found", e);
    return null;
  }
}
export default {
  Auth: {
    region: process.env.REGION,
    userPoolId: process.env.ADMIN_AUDIT_USER_POOL,
    userPoolWebClientId: process.env.ADMIN_AUDIT_CLIENT
  },
  API: {
    endpoints: [
      {
        name: "audit-apis",
        endpoint: process.env.AWS_AUDIT_APIS_ENDPOINT,
        custom_header: async () => {
          const accessToken = await getAccessToken();
          return { Authorization: accessToken };
        }
      }
    ]
  }
};
