import React, { useState } from 'react';
import {
  Modal,
  Message,
  Button,
  Icon,
  Form,
  Divider,
  Header
} from 'semantic-ui-react';

export default ({ createCustomer }) => {
  //ui state
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState([]);

  //form fields
  const [domain, setCustomerId] = useState('');
  const [name, setName] = useState('');
  const [adminName, setAdminName] = useState('Admin');
  const [adminEmail, setAdminEmail] = useState('');

  async function createCustomerClose() {
    try {
      await createCustomer({domain, name, adminName, adminEmail});

      // reset state
      setCustomerId('');
      setName('');
      setAdminName('Admin');
      setAdminEmail('');

      setOpen(false);
    } catch (e) {
      console.log("the error", e.status);
      setErrors(e.response.data.errors);
    }
  }

  const createCustomerButton = (
    <Button floated='right' icon labelPosition='left' primary size='small'>
      <Icon name='user' /> Add Customer
    </Button>
  );

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={createCustomerButton}>
      <Modal.Header>Create a new customer</Modal.Header>
      <Modal.Content>
        <Form onSubmit={createCustomerClose}>
          <Divider horizontal>
            <Header as='h4'>
              <Icon name='info' />
              Customer Details
            </Header>
          </Divider>
          <Form.Field>
            <label>Customer Name</label>
            <input
              placeholder='Customer Name'
              onChange={(e) => setName(e.target.value)}
              value={name}/>
          </Form.Field>
          <Form.Field>
            <label>Customer Domain</label>
            <input
              placeholder='Domain'
              onChange={(e) => setCustomerId(e.target.value)}
              value={domain}/>
          </Form.Field>
          <Divider horizontal>
            <Header as='h4'>
              <Icon name='user' />
              Admin User
            </Header>
          </Divider>
          <Form.Field>
            <label>Admin Username</label>
            <input
              placeholder='Admin'
              onChange={(e) => setAdminName(e.target.value)}
              value={adminName}/>
          </Form.Field>
          <Form.Field>
            <label>Admin Email</label>
            <input
              placeholder='email'
              onChange={(e) => setAdminEmail(e.target.value)}
              value={adminEmail}/>
          </Form.Field>
          {errors.map(({title, detail}, i) => (
            <Message negative
              key={i}
              header={title}
              content={detail}/>
          ))}
          <Button primary type='submit'>Create Customer</Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
}
