import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Message,
  Button,
  Icon,
  Form,
  Divider,
  Header
} from "semantic-ui-react";

const CreateIdentityProvider = ({ customerId, createIdentityProvider }) => {
  // ui state
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState([]);

  // form fields
  const [type] = useState("OIDC");
  const [name, setName] = useState("");
  const [scopes, setScopes] = useState("");
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [issuer, setIssuer] = useState("");

  async function createIdentityProviderSubmit() {
    try {
      await createIdentityProvider(customerId, {
        name,
        type,
        clientId,
        clientSecret,
        scopes,
        issuer
      });

      // reset state

      setOpen(false);
    } catch (e) {
      console.error("the error", e.status);
      setErrors(e.response.data.errors);
    }
  }

  const createProviderButton = (
    <Button floated="right" icon labelPosition="left" primary size="small">
      <Icon name="plus" />
      Add Identity Provider
    </Button>
  );

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={createProviderButton}
    >
      <Modal.Header>Create a new identity provider</Modal.Header>
      <Modal.Content>
        <Form onSubmit={createIdentityProviderSubmit}>
          <Divider horizontal>
            <Header as="h4">
              <Icon name="sitemap" />
              Identity Provider Details
            </Header>
          </Divider>
          <Form.Field>
            <label htmlFor="name">Name</label>
            <input
              id="name"
              onChange={e => setName(e.target.value)}
              value={name}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="clientId">Client Id</label>
            <input
              id="clientId"
              onChange={e => setClientId(e.target.value)}
              value={clientId}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="clientSecret">Client Secret</label>
            <input
              id="clientSecret"
              onChange={e => setClientSecret(e.target.value)}
              value={clientSecret}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="authoriceScopes">Authorize Scopes</label>
            <input
              id="authoriceScopes"
              placeholder="openid, etc"
              onChange={e => setScopes(e.target.value)}
              value={scopes}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="issuer">Issuer</label>
            <input
              id="issuer"
              onChange={e => setIssuer(e.target.value)}
              value={issuer}
            />
          </Form.Field>
          {errors.map(({ title, detail }) => (
            <Message negative key={title} header={title} content={detail} />
          ))}
          <Button primary type="submit">
            Create Identity Provider
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

CreateIdentityProvider.propTypes = {
  customerId: PropTypes.string.isRequired,
  createIdentityProvider: PropTypes.func.isRequired
};

export default CreateIdentityProvider;
