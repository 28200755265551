const COMPONENT_ROUTE_KEYS = [
    'confirmResetPassword',
    'confirmSignIn',
    'confirmSignUp',
    'confirmVerifyUser',
    'forceNewPassword',
    'resetPassword',
    'setupTOTP',
    'signIn',
    'signUp',
    'verifyUser',
];
const COMPONENT_ROUTE_NAMES = [
    'ConfirmResetPassword',
    'ConfirmSignIn',
    'ConfirmSignUp',
    'ConfirmVerifyUser',
    'ForceNewPassword',
    'ResetPassword',
    'SetupTOTP',
    'SignIn',
    'SignUp',
    'VerifyUser',
];

export { COMPONENT_ROUTE_KEYS, COMPONENT_ROUTE_NAMES };
