import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { API } from "aws-amplify";
import { Modal, Button, Icon, Table, Container } from "semantic-ui-react";

const UserBackups = ({ customerId, poolInfo }) => {
  const { num, userPoolId } = poolInfo;
  // ui state
  const [open, setOpen] = useState(false);
  const [backups, setBackups] = useState([]);
  const [restoreStatus, setRestoreStatus] = useState();
  const [backingUp, setBackingUp] = useState(false);
  const [restoring, setRestoring] = useState(false);

  const refresh = () => {
    API.get("audit-apis", `/customers/${customerId}/user-backups`, {}).then(
      result => {
        const sorted = result.data.sort(backup => backup.lastModified);
        setBackups(sorted);
      }
    );
  };

  useEffect(() => {
    if (open) {
      refresh();
    }
  }, [customerId, open]);

  const createUserBackup = useCallback(() => {
    setBackingUp(true);
    API.post(
      "audit-apis",
      `/customers/${customerId}/user-backups/${userPoolId}/backup`,
      {
        body: {}
      }
    ).then(() => {
      setBackingUp(false);
      refresh();
    });
  }, [customerId]);

  const restore = useCallback(
    key => {
      setRestoring(true);
      API.post(
        "audit-apis",
        `/customers/${customerId}/user-backups/${userPoolId}/restore`,
        {
          body: { backupKey: key }
        }
      ).then(result => {
        setRestoreStatus(result);
        setRestoring(false);
      });
    },
    [customerId]
  );

  const createUserPoolButton = (
    <Button floated="right" icon labelPosition="left" primary size="small">
      <Icon name="plus" />
      Manage Backups
    </Button>
  );

  return (
    <Modal
      open={open}
      onOpen={e => {
        e.preventDefault();
        setOpen(true);
      }}
      onClose={() => setOpen(false)}
      trigger={createUserPoolButton}
    >
      <Modal.Header>{`Manage User Backups (pool${num})`}</Modal.Header>
      <Modal.Content>
        <Container>
          {restoreStatus && (
            <span>
              {`created: ${restoreStatus.created}, skipped: ${
                restoreStatus.skipped
              }, errored: ${restoreStatus.errored}`}
            </span>
          )}
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Key</Table.HeaderCell>
                <Table.HeaderCell>Last Modified</Table.HeaderCell>
                <Table.HeaderCell>Size</Table.HeaderCell>
                <Table.HeaderCell />
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {backups.map(backup => {
                return (
                  <Table.Row key={backup.key}>
                    <Table.Cell>
                      {backup.key.replace("__mm_backup__/cognito", "")}
                    </Table.Cell>
                    <Table.Cell>{backup.lastModified}</Table.Cell>
                    <Table.Cell>{backup.size}</Table.Cell>
                    <Table.Cell>
                      <Button
                        primary
                        size="mini"
                        disabled={restoring}
                        onClick={e => {
                          e.preventDefault();
                          restore(backup.key);
                        }}
                      >
                        Restore
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Container>
      </Modal.Content>
      <Modal.Actions>
        <Button disabled={backingUp} onClick={createUserBackup}>
          Create User Backup
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

UserBackups.propTypes = {
  customerId: PropTypes.string.isRequired,
  poolInfo: PropTypes.shape({
    num: PropTypes.string,
    userPoolId: PropTypes.string
  }).isRequired
};

export default UserBackups;
