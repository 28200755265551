import React, { Component } from "react";
import { API } from "aws-amplify";
import CustomerTable from "./customer-table";

class CustomerList extends Component {
  state = {
    customers: [],
    customerDetail: null
  };

  constructor(params) {
    super(params);
    this.getCustomerDetail = this.getCustomerDetail.bind(this);
    this.createCustomer = this.createCustomer.bind(this);
    this.deleteCustomer = this.deleteCustomer.bind(this);
  }

  componentDidMount() {
    this.getCustomers();
  }

  async getCustomerDetail(customerId) {
    const customerDetail = await API.get(
      "audit-apis",
      `/customers/${customerId}`,
      {}
    );
    this.setState({ customerDetail: customerDetail.data });
  }

  async getCustomers() {
    const customers = await API.get("audit-apis", "/customers", {});
    this.setState({ customers: customers.data });
  }

  async createCustomer(customer) {
    await API.post("audit-apis", "/customers", { body: customer });
    await this.getCustomers();
  }

  async deleteCustomer(domain) {
    await API.del("audit-apis", `/customers/${domain}`, {});
    await this.getCustomers();
  }

  render() {
    const { customers } = this.state;
    const { getCustomerDetail, createCustomer, deleteCustomer } = this;

    const params = {
      customers,
      getCustomerDetail,
      createCustomer,
      deleteCustomer
    };

    return <CustomerTable {...params} />;
  }
}
export default CustomerList;
