import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Message,
  Button,
  Icon,
  Form,
  Divider,
  Header
} from "semantic-ui-react";

const CreateUserPool = ({ customerId, createUserPool }) => {
  // ui state
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState([]);

  // form fields
  const [adminName, setAdminName] = useState("Admin");
  const [adminEmail, setAdminEmail] = useState("");

  async function createUserPoolSubmit(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    try {
      await createUserPool(customerId, { adminName, adminEmail });

      // reset state

      setOpen(false);
    } catch (err) {
      console.error("the error", err.status);
      setErrors(err.response.data.errors);
    }
  }

  const createUserPoolButton = (
    <Button floated="right" icon labelPosition="left" primary size="small">
      <Icon name="plus" />
      Create User Pool
    </Button>
  );

  return (
    <Modal
      open={open}
      onOpen={e => {
        e.preventDefault();
        setOpen(true);
      }}
      onClose={() => setOpen(false)}
      trigger={createUserPoolButton}
    >
      <Modal.Header>Create a new customer</Modal.Header>
      <Modal.Content>
        <Form onSubmit={createUserPoolSubmit}>
          <Divider horizontal>
            <Header as="h4">
              <Icon name="info" />
              User Pool Info
            </Header>
          </Divider>
          <Form.Field>
            <label htmlFor="admin">Admin Username</label>
            <input
              id="admin"
              placeholder="Admin"
              onChange={e => setAdminName(e.target.value)}
              value={adminName}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="email">Admin Email</label>
            <input
              id="email"
              placeholder="email"
              onChange={e => setAdminEmail(e.target.value)}
              value={adminEmail}
            />
          </Form.Field>
          {errors.map(({ title, detail }, i) => (
            <Message negative key={i} header={title} content={detail} />
          ))}
          <Button primary type="submit">
            Create User Pool
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

CreateUserPool.propTypes = {
  customerId: PropTypes.string.isRequired,
  createUserPool: PropTypes.func.isRequired
};

export default CreateUserPool;
