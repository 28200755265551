/* eslint react/jsx-filename-extension: 0 */
import { Amplify } from "aws-amplify";
import React from "react";
import { Router } from "react-router-dom";
import { createRoot } from "react-dom";
import history from "./history";
import Routes from "./routes";
import amplifyConfig from "./config/amplify";

import "../semantic-ui/semantic.less";

Amplify.configure(amplifyConfig);

const root = createRoot(document.getElementById("root"));

root.render(
  <Router history={history}>
    <Routes />
  </Router>
);
