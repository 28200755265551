import { LazyJsonString as __LazyJsonString, SmithyException as __SmithyException } from "@aws-sdk/smithy-client";
import { MetadataBearer as $MetadataBearer } from "@aws-sdk/types";

/**
 * <p>Provide a valid value for the field or parameter.</p>
 */
export interface InvalidInputException extends __SmithyException, $MetadataBearer {
  name: "InvalidInputException";
  $fault: "client";
  message?: string;
}

export namespace InvalidInputException {
  export const filterSensitiveLog = (obj: InvalidInputException): any => ({
    ...obj,
  });
}

/**
 * <p>Represents user interaction event information sent using the
 *       <code>PutEvents</code> API.</p>
 */
export interface Event {
  /**
   * <p>An ID associated with the event. If an event ID is not provided, Amazon Personalize generates
   *       a unique ID for the event. An event ID is not used as an input to the model. Amazon Personalize uses
   *       the event ID to distinquish unique events. Any subsequent events after the first with the
   *       same event ID are not used in model training.</p>
   */
  eventId?: string;

  /**
   * <p>The type of event, such as click or download. This property corresponds to the <code>EVENT_TYPE</code>
   *       field of your Interactions schema and depends on the types of events you are tracking.</p>
   */
  eventType: string | undefined;

  /**
   * <p>The event value that corresponds to the <code>EVENT_VALUE</code> field of the Interactions schema.</p>
   */
  eventValue?: number;

  /**
   * <p>The item ID key that corresponds to the <code>ITEM_ID</code> field of the Interactions schema.</p>
   */
  itemId?: string;

  /**
   * <p>A string map of event-specific data that you might choose to record. For example, if a
   *       user rates a movie on your site, other than movie ID (<code>itemId</code>) and rating (<code>eventValue</code>)
   *       , you might also send the number of movie ratings made by the user.</p>
   *          <p>Each item in the map consists of a key-value pair. For example,</p>
   *
   *          <p>
   *             <code>{"numberOfRatings": "12"}</code>
   *          </p>
   *          <p>The keys use camel case names that match the fields in the Interactions
   *       schema. In the above example, the <code>numberOfRatings</code> would match the
   *       'NUMBER_OF_RATINGS' field defined in the Interactions schema.</p>
   */
  properties?: __LazyJsonString | string;

  /**
   * <p>The timestamp (in Unix time) on the client side when the event occurred.</p>
   */
  sentAt: Date | undefined;

  /**
   * <p>The ID of the recommendation.</p>
   */
  recommendationId?: string;

  /**
   * <p>A list of item IDs that represents the sequence of items you have shown the user. For example, <code>["itemId1", "itemId2", "itemId3"]</code>.</p>
   */
  impression?: string[];
}

export namespace Event {
  export const filterSensitiveLog = (obj: Event): any => ({
    ...obj,
  });
}

export interface PutEventsRequest {
  /**
   * <p>The tracking ID for the event.
   *       The ID is generated by a call to the
   *       <a href="https://docs.aws.amazon.com/personalize/latest/dg/API_CreateEventTracker.html">CreateEventTracker</a> API.</p>
   */
  trackingId: string | undefined;

  /**
   * <p>The user associated with the event.</p>
   */
  userId?: string;

  /**
   * <p>The session ID associated with the user's visit. Your application generates the sessionId when a user first visits your website or uses your application.
   *       Amazon Personalize uses the sessionId to associate events with the user before they log in. For more information see <a>event-record-api</a>.</p>
   */
  sessionId: string | undefined;

  /**
   * <p>A list of event data from the session.</p>
   */
  eventList: Event[] | undefined;
}

export namespace PutEventsRequest {
  export const filterSensitiveLog = (obj: PutEventsRequest): any => ({
    ...obj,
  });
}

/**
 * <p>Represents item metadata added to an Items dataset using the
 *       <code>PutItems</code> API.</p>
 */
export interface Item {
  /**
   * <p>The ID associated with the item.</p>
   */
  itemId: string | undefined;

  /**
   * <p>A string map of item-specific metadata. Each element in the map consists of a key-value pair. For example,
   *     </p>
   *          <p>
   *             <code>{"numberOfRatings": "12"}</code>
   *          </p>
   *          <p>The keys use camel case names that match the fields in the Items
   *       schema. In the above example, the <code>numberOfRatings</code> would match the
   *       'NUMBER_OF_RATINGS' field defined in the Items schema.</p>
   */
  properties?: __LazyJsonString | string;
}

export namespace Item {
  export const filterSensitiveLog = (obj: Item): any => ({
    ...obj,
  });
}

export interface PutItemsRequest {
  /**
   * <p>The Amazon Resource Number (ARN) of the Items dataset you are adding the item or items to.</p>
   */
  datasetArn: string | undefined;

  /**
   * <p>A list of item data.</p>
   */
  items: Item[] | undefined;
}

export namespace PutItemsRequest {
  export const filterSensitiveLog = (obj: PutItemsRequest): any => ({
    ...obj,
  });
}

/**
 * <p>Could not find the specified resource.</p>
 */
export interface ResourceNotFoundException extends __SmithyException, $MetadataBearer {
  name: "ResourceNotFoundException";
  $fault: "client";
  message?: string;
}

export namespace ResourceNotFoundException {
  export const filterSensitiveLog = (obj: ResourceNotFoundException): any => ({
    ...obj,
  });
}

/**
 * <p>Represents user metadata added to a Users dataset using the
 *       <code>PutUsers</code> API.</p>
 */
export interface User {
  /**
   * <p>The ID associated with the user.</p>
   */
  userId: string | undefined;

  /**
   * <p>A string map of user-specific metadata. Each element in the map consists of a key-value pair. For example,
   *     </p>
   *          <p>
   *             <code>{"numberOfVideosWatched": "45"}</code>
   *          </p>
   *          <p>The keys use camel case names that match the fields in the Users
   *       schema. In the above example, the <code>numberOfVideosWatched</code> would match the
   *       'NUMBER_OF_VIDEOS_WATCHED' field defined in the Users schema.</p>
   */
  properties?: __LazyJsonString | string;
}

export namespace User {
  export const filterSensitiveLog = (obj: User): any => ({
    ...obj,
  });
}

export interface PutUsersRequest {
  /**
   * <p>The Amazon Resource Number (ARN) of the Users dataset you are adding the user or users to.</p>
   */
  datasetArn: string | undefined;

  /**
   * <p>A list of user data.</p>
   */
  users: User[] | undefined;
}

export namespace PutUsersRequest {
  export const filterSensitiveLog = (obj: PutUsersRequest): any => ({
    ...obj,
  });
}
