// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
export { SERVICE_NAME } from './base';
export { getObject, getPresignedGetObjectUrl, } from './getObject';
export { listObjectsV2, } from './listObjectsV2';
export { putObject } from './putObject';
export { createMultipartUpload, } from './createMultipartUpload';
export { uploadPart } from './uploadPart';
export { completeMultipartUpload, } from './completeMultipartUpload';
export { listParts } from './listParts';
export { abortMultipartUpload, } from './abortMultipartUpload';
export { copyObject } from './copyObject';
export { headObject } from './headObject';
export { deleteObject, } from './deleteObject';
